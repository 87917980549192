import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,523.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
       <path d="M7376 7207 c-26 -29 -54 -60 -63 -69 -9 -10 -13 -21 -9 -25 15 -15
114 -6 131 11 9 9 20 16 25 16 30 0 8 101 -24 113 -9 4 -31 -13 -60 -46z"/>
<path d="M7492 7073 l-24 -22 21 -57 c19 -48 21 -69 15 -144 l-7 -87 37 -29
c20 -16 36 -33 36 -37 0 -4 12 -19 26 -32 22 -21 36 -25 83 -24 l56 1 -46 14
c-77 24 -95 91 -40 148 19 20 39 36 44 36 5 0 20 9 34 19 25 21 53 18 53 -4 0
-7 10 -19 23 -28 12 -8 34 -25 48 -38 45 -41 119 -85 119 -70 0 5 -11 12 -25
15 -14 4 -25 13 -25 21 0 8 -6 15 -12 15 -7 1 -44 32 -83 70 -45 46 -77 70
-92 70 -14 0 -23 6 -23 15 0 8 -4 15 -9 15 -11 0 -61 47 -61 57 0 17 -104 103
-111 92 -3 -6 -8 -7 -10 -3 -2 5 -15 -1 -27 -13z"/>
<path d="M7255 7026 c-50 -22 -93 -97 -74 -127 6 -11 9 -10 15 4 10 27 24 20
24 -13 0 -40 26 -40 35 0 5 24 14 32 36 37 34 6 38 22 8 30 -16 4 -17 8 -7 14
28 18 59 8 100 -33 23 -23 38 -34 35 -24 -4 10 -7 24 -7 32 0 8 -16 31 -35 50
-41 41 -85 51 -130 30z m13 -85 c3 -8 -1 -12 -9 -9 -7 2 -15 10 -17 17 -3 8 1
12 9 9 7 -2 15 -10 17 -17z"/>
<path d="M2797 6363 c-4 -3 -7 -238 -7 -522 l0 -516 345 0 345 0 2 170 3 170
820 0 c649 0 834 -3 885 -13 157 -33 273 -118 330 -245 18 -39 35 -74 37 -76
2 -3 38 -4 81 -3 188 6 334 -29 406 -96 28 -26 86 -134 86 -159 0 -9 22 -13
75 -13 l75 0 0 138 c-1 387 -93 669 -281 863 -183 187 -403 275 -754 299 -156
11 -2438 14 -2448 3z"/>
<path d="M5747 6361 c-14 -5 -1 -15 54 -41 212 -101 383 -291 471 -522 20 -51
38 -100 41 -109 5 -15 43 -17 354 -21 333 -5 352 -6 443 -31 199 -53 285 -140
297 -300 13 -176 -92 -297 -306 -349 -79 -19 -121 -21 -518 -26 l-432 -4 -3
-342 c-2 -187 -2 -344 0 -348 5 -11 780 -9 954 2 573 37 890 259 986 688 21
94 23 396 4 512 -33 196 -87 350 -165 467 -177 264 -441 394 -859 423 -162 11
-1293 12 -1321 1z"/>
<path d="M7550 6346 c0 -17 19 -23 95 -31 165 -18 168 -18 161 -7 -4 7 -24 12
-45 12 -20 0 -41 4 -46 9 -6 5 -40 11 -77 14 -37 3 -72 8 -78 12 -5 3 -10 -1
-10 -9z"/>
<path d="M7505 6340 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M743 4943 c-55 -3 -63 -7 -63 -24 0 -11 8 -23 18 -26 9 -4 20 -11 24
-17 4 -6 8 -97 8 -202 0 -195 -4 -218 -41 -226 -12 -2 -14 -8 -9 -24 8 -19 19
-21 137 -27 189 -10 267 17 332 113 78 116 60 302 -39 380 -71 56 -137 66
-367 53z m224 -73 c53 -32 77 -91 78 -190 0 -148 -47 -225 -136 -218 l-41 3 1
205 c0 113 0 208 1 213 0 15 66 6 97 -13z"/>
<path d="M2550 4930 c-107 -36 -166 -134 -164 -270 3 -148 99 -250 250 -266
64 -7 157 8 210 33 l32 16 -5 78 c-6 78 -6 78 21 85 29 7 33 25 10 48 -23 23
-229 23 -238 0 -12 -32 -6 -44 24 -44 36 0 50 -23 50 -84 0 -42 -2 -45 -34
-56 -72 -23 -138 20 -155 102 -26 119 -7 242 42 284 27 23 38 25 81 21 40 -3
55 -11 78 -36 15 -17 28 -38 28 -47 0 -12 10 -14 43 -12 l42 3 -3 59 c-6 102
-1 99 -138 102 -96 3 -130 0 -174 -16z"/>
<path d="M3116 4934 c-104 -38 -159 -125 -160 -254 0 -97 16 -151 61 -201 48
-54 100 -79 177 -86 161 -15 273 85 284 253 12 183 -92 305 -258 303 -36 0
-83 -7 -104 -15z m131 -44 c51 -18 67 -60 71 -190 6 -178 -24 -250 -103 -250
-76 0 -111 89 -103 255 6 102 26 158 67 180 32 17 31 17 68 5z"/>
<path d="M3744 4943 c-41 -3 -53 -8 -57 -23 -3 -14 2 -21 17 -26 11 -4 24 -12
28 -18 12 -17 9 -385 -2 -407 -6 -10 -20 -19 -30 -19 -23 0 -25 -9 -7 -33 11
-15 33 -18 157 -20 138 -2 148 -1 200 24 110 52 155 127 154 259 0 73 -4 93
-27 138 -30 57 -90 103 -157 122 -33 9 -164 11 -276 3z m233 -73 c54 -32 76
-90 77 -195 1 -141 -36 -203 -131 -218 -18 -3 -36 0 -42 6 -10 12 -15 403 -5
418 9 16 68 9 101 -11z"/>
<path d="M4764 4912 c-4 -18 -7 -65 -8 -105 l-1 -72 33 -3 c20 -2 34 2 36 10
2 7 9 32 15 55 14 52 45 83 83 83 l28 0 0 -199 c0 -195 -1 -200 -22 -215 -12
-9 -35 -16 -50 -16 -31 0 -33 -3 -18 -31 10 -17 23 -19 155 -19 132 0 145 2
155 19 14 28 13 31 -16 31 -14 0 -34 5 -45 10 -18 10 -19 23 -19 215 0 221 0
222 52 194 22 -12 57 -82 58 -117 0 -21 4 -23 38 -20 l37 3 -3 75 c-1 41 -6
88 -10 103 l-8 29 -242 1 -242 2 -6 -33z"/>
<path d="M1307 4919 c-6 -21 -3 -23 46 -33 15 -4 27 -12 27 -18 0 -6 -30 -93
-66 -192 -64 -174 -92 -226 -121 -226 -9 0 -13 -8 -11 -22 3 -22 7 -23 108
-23 99 0 105 1 108 21 2 15 -3 23 -17 27 -26 6 -35 29 -27 62 6 25 8 25 105
25 l99 0 7 -30 c7 -33 -7 -60 -31 -60 -15 0 -17 -10 -8 -34 5 -14 34 -16 218
-16 214 0 236 4 236 37 0 7 -8 13 -19 13 -37 0 -42 22 -39 171 l3 143 119
-164 c65 -91 128 -173 138 -182 13 -12 37 -18 64 -18 l44 0 0 224 c0 123 3
232 6 241 4 9 18 21 31 26 16 6 23 15 21 27 -3 14 -17 17 -97 20 -88 3 -121
-5 -121 -28 0 -5 14 -13 30 -19 l30 -10 0 -135 c0 -75 -2 -136 -4 -136 -2 0
-47 63 -101 140 -137 196 -130 190 -231 190 -71 0 -83 -3 -86 -17 -3 -12 6
-23 27 -33 l30 -15 3 -193 c3 -206 -2 -232 -42 -232 -46 0 -64 31 -140 245
-41 116 -78 218 -81 228 -6 15 -22 17 -130 17 -115 0 -123 -1 -128 -21z m192
-197 c17 -51 31 -99 31 -107 0 -12 -15 -15 -75 -15 l-74 0 13 43 c7 23 23 75
36 115 12 41 26 70 31 65 4 -4 21 -50 38 -101z"/>
<path d="M4320 4931 c0 -5 -3 -16 -6 -25 -5 -12 1 -16 22 -17 68 -1 68 2 -8
-204 -68 -183 -95 -235 -125 -235 -9 0 -13 -8 -11 -22 3 -22 8 -23 102 -26 73
-2 102 1 108 10 13 21 9 35 -12 41 -25 6 -34 29 -26 62 6 25 8 25 105 25 l99
0 7 -30 c7 -33 -7 -60 -31 -60 -15 0 -17 -10 -8 -34 5 -13 26 -16 125 -16 110
0 119 2 129 21 7 11 10 21 8 22 -63 32 -62 31 -106 152 -23 66 -61 171 -83
233 l-41 112 -124 0 c-69 0 -124 -4 -124 -9z m189 -211 c17 -52 31 -100 31
-107 0 -9 -20 -13 -75 -13 l-74 0 13 43 c40 130 61 186 67 180 4 -5 22 -51 38
-103z"/>
<path d="M5370 4931 c0 -5 -3 -16 -6 -25 -5 -12 0 -16 19 -16 35 0 57 -10 57
-25 0 -7 -30 -93 -66 -191 -65 -173 -92 -224 -121 -224 -9 0 -13 -8 -11 -22 3
-22 7 -23 108 -23 99 0 105 1 108 21 2 15 -3 23 -17 27 -26 6 -35 29 -27 62 6
25 8 25 105 25 l99 0 7 -30 c7 -33 -7 -60 -31 -60 -8 0 -14 -6 -14 -13 0 -31
25 -37 141 -37 112 0 117 1 122 22 4 12 5 22 4 23 -1 0 -15 7 -32 15 -26 14
-36 33 -78 150 -27 74 -65 179 -84 233 l-36 97 -124 0 c-68 0 -123 -4 -123 -9z
m189 -209 c17 -54 31 -103 31 -110 0 -8 -23 -12 -76 -12 -57 0 -75 3 -71 13 2
6 19 58 38 114 18 56 36 100 40 97 5 -2 22 -48 38 -102z"/>
<path d="M6096 4121 c-48 -94 -137 -144 -286 -161 -52 -6 -128 -10 -169 -8
l-74 3 -22 -68 c-56 -166 -159 -258 -342 -304 -62 -16 -141 -18 -893 -18
l-825 0 -3 195 -2 195 -345 0 -345 0 -2 -539 c-2 -371 0 -540 8 -543 5 -2 590
-2 1300 0 1268 3 1290 3 1379 24 236 55 397 139 528 277 185 194 277 481 277
869 l0 127 -80 0 -80 0 -24 -49z"/>
<path d="M4613 3944 c-2 -5 -2 -71 -1 -145 l3 -134 240 -3 c251 -3 332 5 410
38 l45 19 0 118 0 118 -346 0 c-233 0 -348 -3 -351 -11z"/>
<path d="M6317 3553 c-2 -5 -10 -26 -17 -48 -83 -265 -270 -487 -505 -596 -36
-17 -65 -33 -65 -35 0 -2 536 -4 1190 -4 l1190 0 0 345 0 345 -894 0 c-492 0
-897 -3 -899 -7z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
